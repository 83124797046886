import { List, ListItem, ListItemText, Typography, Divider } from '@mui/material'
import React, {Fragment, useEffect, useState} from 'react'


const DATA = [
  {
    company: "사회평론",
    job: "웹 개발자",
    content: "사내 프로젝트 개발 및 레거시 시스템 리팩토링",
    date: "Jul. 2021 - Present",
    exp: "- Spring boot, JPA, MariaDB, Linux, AWS 환경에서의 개발 경험",
    mr: 15.4
  }, 
  {
    company: "갈렙ABC",
    job: "웹 개발자",
    content: "사내 프로젝트 개발 및 기존 솔루션 유지보수",
    date: "Sep. 2019 - Jun. 2021",
    exp: "- Spring boot, MyBatis, Oracle 환경에서의 개발 경험",
    exp2: "- Go, SQLite, PostgreSQL 환경에서의 개발 경험",
  },
  {
    company: "KH 정보교육원",
    job: "웹 개발자",
    content: "Java 기초, 웹 개발 기초에 대한 학습",
    date: "Jan. 2019 - Jul. 2019",
    exp: "- Spring boot, MyBatis, Oracle 환경에서의 개발 경험",
  },
  {
    company: "스크린SPE",
    job: "필드 엔지니어",
    content: "반도체 FAB 내에서 설비의 Set-up",
    date: "Dec. 2017 - Aug. 2018",
    exp: "- Cleaning 설비 유지보수 경험",
    contentMr: 17
  },
  {
    company: "중앙대학교",
    job: "화학신소재공학",
    date: "Mar. 2015 - Aug. 2017",
  },
  {
    company: "경기과학기술대학교",
    job: "자동차과",
    date: "Mar. 2015 - Aug. 2017",
  },
  {
    company: "백영고등학교",
    date: "Mar. 2015 - Aug. 2017",
  },
];

const DATA_MO = [
  {
    company: "사회평론",
    job: "웹 개발자",
    content: "사내 프로젝트 개발 및 레거시 시스템 리팩토링",
    date: "Jul. 2021 - Present",
    exp: "Spring boot, JPA, MySql, Linux, AWS",
  },
  {
    company: "갈렙ABC",
    job: "웹 개발자",
    content: "사내 프로젝트 개발 및 기존 솔루션 유지보수",
    date: "Sep. 2019 - Jun. 2021",
    exp: "Spring boot, MyBatis, Oracle, Go",
  },
  {
    company: "KH 정보교육원",
    job: "웹 개발자",
    content: "Java 프레임워크 개발자 양성과정",
    date: "Jan. 2019 - Jul. 2019",
    exp: "Spring boot, MyBatis, Oracle",
  },
  {
    company: "스크린SPE",
    job: "필드 엔지니어",
    content: "반도체 FAB 내에서 설비의 Set-up",
    date: "Dec. 2017 - Aug. 2018",
    exp: "Cleaning 설비 유지보수",
  },
  {
    company: "중앙대학교",
    job: "화학신소재공학",
    date: "Mar. 2015 - Aug. 2017",
  },
  {
    company: "경기과학기술대학교",
    job: "자동차과",
    date: "Mar. 2009 - Feb. 2014",
  },
  {
    company: "백영고등학교",
    date: "Mar. 2006 - Feb. 2009",
  },
]

export default function Career() {
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);

  const handleResize = () => {
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    }
  }, []); 


  if (windowSize >= 600) {
    return (
      <>
        <List sx={{ bgcolor: 'background.paper' }}>
          {
            DATA.map(d => {
              return (
                <>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={d.company}
                      secondary={
                        <Fragment>
                          <Typography
                              sx={{ display: 'inline' }}
                              variant="body2"
                              color="text.primary"
                              mr={d.mr}
                          >
                            {d.job}
                          </Typography>
                          <br />
                          {d.date}
                        </Fragment>
                      }
                    />
                    <ListItemText
                      primary={d.content}
                      secondary={
                        <Fragment>
                          <Typography
                              sx={{ display: 'inline' }}
                              variant="body2"
                              color="text.primary"
                              mr={d.contentMr}
                          >
                            {d.exp}
                          </Typography>
                          <br />
                          <Typography
                            sx={{ display: 'inline' }}
                            variant="body2"
                            color="text.primary"
                          >
                             {d.exp2}
                          </Typography>   
                        </Fragment>
                      }
                    />
                  </ListItem>
                  <br />
                  <Divider variant="fullWidth" component="li" />
                  <br /> 
                </>
              );  
            })
          }
        </List>
      </>
    );
  } else {
    return (
      <>
        <List sx={{ bgcolor: 'background.paper' }}>
          {
            DATA_MO.map(d => {
              return (
                <>
                  <ListItem alignItems="center">
                    <ListItemText
                      primary={d.company}
                      sx={{ textAlign: 'center' }}
                      secondary={
                        <Fragment>
                          <Typography
                              sx={{ display: 'inline' }}
                              variant="body2"
                              color="text.primary"
                          >
                            {d.job}
                          </Typography>
                          <br />
                          {d.date}
                          <br />
                          {d.content}
                          <br />
                          {d.exp}
                        </Fragment>
                      }
                    />
                  </ListItem>    
                  <Divider variant="fullWidth" component="li" />
                </>
              )
            })
          }
        </List>
      </>
    );
  }
  
}
