import React from 'react'
import { Box } from '@mui/material';
import Introduce from './components/Introduce';
import Skill from './components/Skill';

const Home = () => {
  
  return (
    <>
      <Box sx={{ m: 3, pb: 3 }}>
        <Introduce />
        <Skill />
      </Box>
    </>
  )
}

export default Home;