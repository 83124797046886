import React from 'react'
import { Typography, Link } from '@mui/material';
import { AiFillGoogleCircle, AiFillGithub } from "react-icons/ai";

import '../assets/css/introduce.css';


const Introduce = () => {
  return (
    <>
      <Typography variant="h6" sx={{ my: 1 }}>
        안녕하세요! 웹개발자 마경환입니다.
      </Typography>
      <Typography sx={{ my: 3 }}>
        <Link target={'_blank'} href="https://github.com/ghma1213" color="inherit" underline='none' >
          <AiFillGithub size={45} className="github" />
        </Link>
        <Link href="mailto:ghma1213@gmail.com" color="inherit" underline='none' >
          <AiFillGoogleCircle size={45} className="gmail" />
        </Link>
      </Typography>
    </>
  )
}

export default Introduce;