import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   // <React.StrictMode>
//     <App />
//   // </React.StrictMode>
// );


const lightTheme = createTheme({
  typography: {
    fontFamily: "'Gowun Dodum', sans-serif"
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#000',
    },
    secondary: {
      main: 'rgba(0, 0, 0, 0.04)',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
      <ThemeProvider theme={lightTheme}>
        <BrowserRouter >
          <App />
        </BrowserRouter>
      </ThemeProvider>
  </React.StrictMode>, 
  document.getElementById('root')
);