import React from 'react'
import { Box, Container, Typography } from '@mui/material'
import { SiJavascript, SiTypescript, SiReact, 
  SiHtml5, SiCss3, /* SiJava, SiSpringboot, */
  /*SiMariadb,*/ SiMaterialui, SiDocker, SiLinux,
   SiAmazonaws } from "react-icons/si";

const Skill = () => {
  return (
    <>
      <Typography variant="h6" sx={{ my: 1, mt: 10 }}>
        이 사이트는 아래 기술들을 사용하여 만들었습니다.
      </Typography>
      <Container maxWidth="md">
        <Box sx={{ bgcolor: "secondary.main", mb: 10 }} >
          <Typography variant="h5" sx={{ my: 1, py: 2 }} >
            Skill List
          </Typography>
          <Typography variant="h6" sx={{ my: 1, py: 2 }} >
            <div><SiJavascript /> Javascript</div>
            <div><SiTypescript /> Typescript</div>
            <div><SiReact /> React</div>
            <div><SiMaterialui /> MaterialUI</div>
            <div><SiHtml5 /> HTML</div>
            <div><SiCss3 /> CSS</div>
            <div><SiDocker /> Docker</div>
            <div><SiLinux /> Linux</div>
            <div><SiAmazonaws /> AWS</div>
          </Typography>
          {/* <Typography variant="h5" sx={{ my: 1, py: 2 }} > */}
            {/* Back-end
          </Typography>
          <Typography variant="h6" sx={{ my: 1, py: 2 }} >
            <div><SiJava /> Java</div>
            <div><SiSpringboot /> Springboot</div>
            <div><SiMariadb /> MariaDB</div>

          </Typography> */}
        </Box>
      </Container>
    </>
  )
}

export default Skill;