import { Box, Container } from '@mui/material';
import React, { Fragment } from 'react'
import Company from './components/Company';
import Side from './components/Side';


function Project() {

  return (
    <>
      <Container maxWidth="lg">
        <Box sx={{ py: 5, mb: 10, width: '100%', bgcolor: 'background.paper' }}>
          <Company />
          <Side />
        </Box>
      </Container>
    </>
  )
}

export default Project;