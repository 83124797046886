import { Container, Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { SiJavascript, SiTypescript, SiReact, 
  SiJquery, SiThymeleaf, SiHtml5, 
  SiCss3, SiJava, SiSpringboot, 
  SiMariadb, SiDocker, SiLinux, 
  SiAmazonaws, SiOracle, SiSqlite,
  SiGo, SiNodedotjs, SiGit, SiPostgresql, 
  SiMongodb, SiPython, SiFastapi } from 'react-icons/si';
import Item from './Item';

export default function Skill() {
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);
  
  const handleResize = () => {
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    }
  }, []); 

  return (
    <>
      <Container maxWidth="md">
      <Typography variant="h6" sx={{ m: 5, my: 1, pb: 5 }}>현재 업무에 사용하거나 사용했던 기술들입니다.</Typography>
      <div style={{fontSize: 'smaller'}}>* 실무에서 사용했던 기술은 색을 넣었습니다.</div>
        <Box sx={{ bgcolor: "secondary.main", pb: 2 }} >
          <Typography variant="h5" sx={{ my: 1, py: 2 }} >
            Front-end
          </Typography>
          <Box sx={windowSize >= 600 ? { display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' } : { display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)' }}>
            <Item style={{background: '#F7DF1E'}}><SiJavascript /> Javascript</Item>
            <Item><SiTypescript /> Typescript</Item>
            <Item><SiReact /> React</Item>
            <Item style={{background: '#0769AD', color: '#fff'}}><SiJquery /> jQuery</Item>
            <Item style={{background: '#005F0F', color: '#fff'}}><SiThymeleaf /> Thymeleaf</Item>
            <Item style={{background: '#E34F26', color: '#fff'}}><SiHtml5 /> HTML</Item>
            <Item style={{background: '#1572B6', color: '#fff'}}><SiCss3 /> CSS</Item>
          </Box>
          <Typography variant="h5" sx={{ my: 1, py: 2 }} >
            Back-end
          </Typography>
          <Box sx={windowSize >= 600 ? { display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' } : { display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)' }}>
            <Item style={{background: '#007396', color: '#fff'}}><SiJava /> Java</Item>
            <Item style={{background: '#6DB33F', color: '#fff'}}><SiSpringboot /> Springboot</Item>
            <Item style={{background: '#00ADD8', color: '#fff'}}><SiGo /> Go</Item>
            <Item><SiNodedotjs /> NodeJS</Item>
            <Item style={{background: '#3776AB', color: '#fff'}}><SiPython /> Python</Item>
            <Item><SiFastapi /> FastAPI</Item>
          </Box>
          <Typography variant="h5" sx={{ my: 1, py: 2 }} >
            DB
          </Typography>
          <Box sx={windowSize >= 600 ? { display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' } : { display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)' }}>
            <Item style={{background: '#003545', color: '#fff'}}><SiMariadb /> MariaDB</Item>
            <Item style={{background: '#F80000', color: '#fff'}}><SiOracle /> Oracle</Item>
            <Item style={{background: '#003B57', color: '#fff'}}><SiSqlite /> SQLite</Item>
            <Item style={{background: '#4169E1', color: '#fff'}}><SiPostgresql /> PosrgreSQL</Item>
            <Item><SiMongodb /> MongoDB</Item>
          </Box>
          <Typography variant="h5" sx={{ my: 1, py: 2 }} >
            DevOps
          </Typography>
          <Box sx={windowSize >= 600 ? { display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' } : { display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)' }}>
            <Item><SiDocker /> Docker</Item>
            <Item style={{background: '#FCC624'}}><SiLinux /> Linux</Item>
            <Item style={{background: '#232F3E', color: '#fff'}}><SiAmazonaws /> AWS</Item>
            <Item style={{background: '#F05032', color: '#fff'}}><SiGit /> Git</Item>
          </Box>
        </Box>
      </Container>
    </>
  )
}
