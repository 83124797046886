import { List, Typography, Divider, Card, CardHeader, Avatar, CardActionArea, CardMedia, CardContent, CardActions } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

import Side1 from './Side1';
import '../assets/company/css/company.css';
import profile from '../assets/company/images/profile.jpg';
import safe from '../assets/company/images/safe.png'

const NEW = "신규 프로젝트 개발";

export default function Side() {
  const [open, setOpen] = useState(false);
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const handleResize = () => {
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    }
  }, []); 

  if (windowSize >= 600) {
    return (
      <>
        <Side1 open={open} handleClose={handleClose} />
        <List sx={{ bgcolor: 'background.paper' }}>
          <Typography variant="h3" sx={{ m: 5, my: 1 }}>SIDE PROJECT</Typography>
          <Typography sx={{pb: 5}}>프로젝트를 클릭하면 자세한 내용을 확인할 수 있습니다.</Typography>
          <Divider variant="fullWidth" />
          
          <div className='card'>
            <Card sx={{ maxWidth: 345, margin: 3, borderRadius: '8px' }} >
              <CardHeader
                avatar={
                  <Link to={'/'} style={{ color: '#000', textDecoration: 'none',}} >
                    <Avatar aria-label="recipe" src={profile} />
                  </Link>
                }
                sx={{ textAlign: 'start', cursor: 'pointer' }}
                title="안전한 생수 찾기"
                subheader={NEW}
              />
              <CardActionArea onClick={handleOpen} >
                <CardMedia
                  component="img"
                  height="140"
                  image={safe}
                  alt="water"
                />
                <CardContent sx={{height: "189.52px"}}>
                  <Typography gutterBottom variant="body2" component="div" sx={{textAlign: 'start'}}>
                    <b>수질 부적합 판정된 생수 찾는 웹애플리케이션 개발</b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <ul>
                      <li className="li">유통제품, 제조업체 수질 부적합 결과를 공공데이터를 활용하여 조회</li>
                      <li className="li">Typescript, React, HTML, Express, MongoDB, Mongoose 기술 사용</li>
                      <li className="li">회원 기능 개발, OpenAPI 활용</li>
                    </ul>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions sx={{justifyContent: 'flex-end'}}>
                <Typography component="div" sx={{textAlign: 'end'}} variant="body2" color="text.secondary">by <b>Ma</b></Typography>
              </CardActions>
            </Card>
          </div>
        </List>
      </>
    )
  } else {
    return (
      <>
        <Side1 open={open} handleClose={handleClose} />
        <List sx={{ bgcolor: 'background.paper' }}>
  
          <Typography variant="h4" sx={{ m: 5, my: 1, pb: 5 }}>SIDE PROJECT</Typography>
          <Typography variant="body2" sx={{pb: 5}}>프로젝트를 클릭하면 자세한 내용을 확인할 수 있습니다.</Typography>

          <Divider variant="fullWidth" />

          <div className='card'>
            <Card sx={{ maxWidth: 345, margin: 3, borderRadius: '8px' }} >
              <CardHeader
                avatar={
                  <Link to={'/'} style={{ color: '#000', textDecoration: 'none',}} >
                    <Avatar aria-label="recipe" src={profile} />
                  </Link>
                }
                sx={{ textAlign: 'start' }}
                title="안전한 생수 찾기"
                subheader={NEW}
              />
              <CardActionArea onClick={handleOpen} >
                <CardMedia
                  component="img"
                  height="140"
                  image={safe}
                  alt="water"
                />
                <CardContent>
                  <Typography gutterBottom variant="body1" component="div" sx={{textAlign: 'start'}}>
                    <b>수질 부적합 판정된 생수 찾는 웹애플리케이션 개발</b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <ul>
                      <li className="li">유통제품, 제조업체 수질 부적합 결과를 공공데이터를 활용하여 조회</li>
                      <li className="li">Typescript, React, HTML, Express, MongoDB, Mongoose 기술 사용</li>
                      <li className="li">회원 기능 개발, OpenAPI 활용</li>
                    </ul>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions sx={{justifyContent: 'flex-end'}}>
                <Typography component="div" sx={{textAlign: 'end'}} variant="body2" color="text.secondary">by <b>Ma</b></Typography>
              </CardActions>
            </Card>
          </div>

        </List>
      </>
    )
  }
}
