import { Box, Tabs, Tab, Typography, Container } from '@mui/material';
import React, { SyntheticEvent, useState } from 'react'
import Career from './components/Career';
import Skill from './components/Skill';
import TabPanel from './components/TabPanel';
import TabProps from './components/TabProps';

function About() {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Box sx={{ m: 3, pb: 3 }}>
        <Typography variant="h6" >
          Java & Spring 4년차 웹개발자입니다.
        </Typography>
        {/* <Typography variant="h6">
          현재 팀 내 개발자로 레거시 프로젝트를 개편하는 작업을 하고 있습니다.
        </Typography> */}
        <Typography variant="h6" sx={{ pb: 5 }}>
          새로운 기술에 관심이 많으며, 성장하고 싶은 욕심이 많습니다.
        </Typography>
        {/* <Typography variant="h6" sx={{ pb: 5 }}>
          사내에서 꼭 필요한 사람이 되기 위해 학습을 하며 노력하고 있습니다.
        </Typography> */}
        <Container maxWidth="lg">
          <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
            <Tabs textColor="primary" indicatorColor="primary" 
              value={value} onChange={handleChange} 
              aria-label="primary tabs example"
              centered
            >
              <Tab label="Career" {...TabProps(0)} />
              <Tab label="Skill" {...TabProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Career />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Skill />
          </TabPanel>
        </Container>
      </Box>
    </>
  )
}

export default About;