import { AppBar, Typography } from '@mui/material';
import React from 'react'


function Footer() {

  return (
    <>
      <AppBar component="footer" position="static"  sx={{ top: 'auto', bottom: 0 }}>
        <Typography
        variant="h6"
        component="div"
        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, py: 2 }}
        >
          Copyright ⓒ 2022-2023 GHMA All Rights Reserved.
        </Typography>
      </AppBar>
    </>
  )
}

export default Footer;