import React, { useEffect, useState } from 'react'
import { Modal, Box, Typography, Fade, Backdrop, Divider } from '@mui/material';
import { CgClose } from "react-icons/cg";
import { MdSearch, MdCelebration, MdBuild, MdTipsAndUpdates, MdOutlineAutoFixHigh } from "react-icons/md";

import home from '../assets/side1/images/home.png';
import home_mo from '../assets/side1/images/home_mo.png';
import water from '../assets/side1/images/water.png';
import water_mo from '../assets/side1/images/water_mo.png';
import regist from '../assets/side1/images/regist.png';
import regist_mo from '../assets/side1/images/regist_mo.png';
import login from '../assets/side1/images/login.png';
import login_mo from '../assets/side1/images/login_mo.png';
import join from '../assets/side1/images/join.png';
import join_mo from '../assets/side1/images/join_mo.png';
import password from '../assets/side1/images/password.png';
import password_mo from '../assets/side1/images/password_mo.png';

import '../assets/side1/css/side1.css';
import ImageSlider from './ImageSlider';

const IMAGES = [
  {
    label: 'Home Screen',
    imgPath: home,
  },
  {
    label: 'Water Screen',
    imgPath: water,
  },
  {
    label: 'Regist Screen',
    imgPath: regist,
  },
  {
    label: 'Login Screen',
    imgPath: login,
  },
  {
    label: 'Join Modal',
    imgPath: join,
  },
  {
    label: 'Password Modal',
    imgPath: password,
  },
];

const IMAGES_MO = [
  {
    label: 'Home Screen',
    imgPath: home_mo,
  },
  {
    label: 'Water Screen',
    imgPath: water_mo,
  },
  {
    label: 'Regist Screen',
    imgPath: regist_mo,
  },
  {
    label: 'Login Screen',
    imgPath: login_mo,
  },
  {
    label: 'Join Modal',
    imgPath: join_mo,
  },
  {
    label: 'Password Modal',
    imgPath: password_mo,
  },
];


export default function Side1({open, handleClose}: any) {

  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);

  const handleResize = () => {
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    }
  }, []); 
  
  let style;

  if (windowSize >= 1441) {
    style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 1200,
      height: 800,
      borderRadius: '8px',
      bgcolor: 'background.paper',
      border: '1px solid #000',
      boxShadow: 24,
      p: 4,
      overflow: 'auto',
    };
  } else if (windowSize >= 600 && windowSize <= 1440) {
    style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 1200,
      height: 600,
      borderRadius: '8px',
      bgcolor: 'background.paper',
      border: '1px solid #000',
      boxShadow: 24,
      p: 4,
      overflow: 'auto'
    };
  } else {
    style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 275,
      height: 700,
      borderRadius: 3,
      bgcolor: 'background.paper',
      border: '1px solid #000',
      boxShadow: 24,
      p: 4,
      overflow:'auto'
    };
  }
  if (windowSize >= 600) {
    return (
      <>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 1000,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography id="modal-modal-title" variant="h4">
                      안전한 생수 찾기
                  </Typography>
                  <CgClose size={30} style={{ cursor: 'pointer' }} onClick={handleClose} />
              </Typography>

              <Divider variant="fullWidth" />

              <Typography sx={{ mt: 3 }} variant="h5" component="h2">
                <MdSearch /> 제조업체 수질 검사 결과 확인
              </Typography>

              <br />

              <div className='slide'>
                <ImageSlider images={IMAGES} />
              </div>

              <br /><br />

              <Typography sx={{ mt: 3 }} variant="h5" component="h2">
                <MdCelebration /> 소개
              </Typography>

              <br />

              <p>시중에 유통되고 있는 생수와 그 생수의 제조업체의 수질 검사 결과를 확인할 수 있는 웹애플리케이션입니다.</p>
              <p>공공데이터포털(https://www.data.go.kr/)의 "환경부 국립환경과학원 먹는샘물 수질검사결과 정보"의 데이터를 참조했습니다.</p>
              <p>Material UI를 이용하여 디자인 되어 있는 컴포넌트를 이용해 제작했습니다.</p>
              <p>백엔드 개발자로 일하면서 Thymeleaf, jQuery 등의 기술 이외에 프론트엔드 기술도 공부해보고자 React, Typescript를 이용해봤습니다.</p>

              <br /><br />
              
              <Typography sx={{ mt: 3 }} variant="h5" component="h2">
                <MdOutlineAutoFixHigh /> 기술
              </Typography>

              <br />

              <ul>
                <li>프론트엔드: Javascript, Typescript, React, MaterialUI, HTML, CSS</li>
                <li>백엔드: Express, MongoDB, Mongoose, data.go.kr</li>
              </ul>  

              <br /><br />

              <Typography sx={{ mt: 3 }} variant="h5" component="h2">
                <MdBuild /> 기능
              </Typography>

              <br />

              <ul>
                <li>제품 브랜드명, 제조회사 등록하기</li>
                <li>공공데이터 포털 API 호출해서 수질검사 확인하기</li>
                <li>회원가입, 로그인(JWT)</li>
                <li>비밀번호 초기화(SMTP)</li>
              </ul>

              <br /><br />

              <Typography sx={{ mt: 3 }} variant="h5" component="h2">
                <MdTipsAndUpdates /> 느낀점
              </Typography>

              <br />

              <p>다양한 회사나 사람들이 빠른 개발을 위해 서버와 클라이언트의 언어를 Javascript로 통일하여 작업하는 것을 보고 저도 빠른 작업을 위해 React와 Express로 개발했습니다.</p>
              <p>그 결과, 기능이나 화면 개발은 속도를 낼 수 있었고, 공공데이터의 데이터를 가공하는데 시간을 집중할 수 있었습니다.</p>
              <p>데이터베이스도 평소 사용하는 RDB가 아닌 NoSQL인 MongoDB와 Mongoose로 개발하며 새로운 기술로 작업하는 재미를 느낄 수 있었습니다.</p>
              <p>"안전한 생수 찾기"를 개발한 이유는 유튜브를 통해 우리가 구입하여 마시는 생수에 문제가 발견되고도 그 업체에 대한 페널티가 미약하다는 것을 알게되어서 입니다.</p>
              <p>유통된 제품 중 수질 검사에 떨어진 제품을 피하기 위해서 입니다.</p>
              <p>제가 사용할 앱을 개발하니 더 재미있고 뜻깊은 프로젝트였습니다.</p>

              <br />

            </Box>
          </Fade>
        </Modal>
      </>
    )
  } else {
    return (
      <>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 1000,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    안전한 생수 찾기 
                  </Typography>
                  <CgClose size={30} style={{ cursor: 'pointer' }} onClick={handleClose} />
              </Typography>
   
              <Divider variant="fullWidth" />

              <Typography sx={{ mt: 3 }} variant="h6" component="h2">
                <MdSearch /> 제조업체 수질 검사 결과 확인
              </Typography>

              <br />

              <div className='slide'>
                <ImageSlider images={IMAGES_MO} />
              </div>

              <br /><br />

              <Typography sx={{ mt: 3 }} variant="h6" component="h2">
                <MdCelebration /> 소개
              </Typography>

              <br />

              <p>시중에 유통되고 있는 생수와 그 생수의 제조업체의 수질 검사 결과를 확인할 수 있는 웹애플리케이션입니다.</p>
              <p>공공데이터포털(https://www.data.go.kr/)의 "환경부 국립환경과학원 먹는샘물 수질검사결과 정보"의 데이터를 참조했습니다.</p>
              <p>Material UI를 이용하여 디자인 되어 있는 컴포넌트를 이용해 제작했습니다.</p>
              <p>백엔드 개발자로 일하면서 Thymeleaf, jQuery 등의 기술 이외에 프론트엔드 기술도 공부해보고자 React, Typescript를 이용해봤습니다.</p>

              <br /><br />

              <Typography sx={{ mt: 3 }} variant="h6" component="h2">
                <MdOutlineAutoFixHigh /> 기술
              </Typography>

              <br />

              <ul>
                <li>프론트엔드</li>
                <p>Javascript, Typescript, React, MaterialUI, HTML, CSS</p>
                <li>백엔드</li>
                <p>Express, MongoDB, Mongoose, data.go.kr</p>
              </ul>  

              <br /><br />

              <Typography sx={{ mt: 3 }} variant="h5" component="h2">
                <MdBuild /> 기능
              </Typography>

              <br />

              <ul>
                <li>제품 브랜드명, 제조회사 등록하기</li>
                <li>공공데이터 포털 API 호출해서 수질검사 확인하기</li>
                <li>회원가입, 로그인(JWT)</li>
                <li>비밀번호 초기화(SMTP)</li>
              </ul>

              <br /><br />

              <Typography sx={{ mt: 3 }} variant="h5" component="h2">
                <MdTipsAndUpdates /> 느낀점
              </Typography>

              <br />

              <p>다양한 회사나 사람들이 빠른 개발을 위해 서버와 클라이언트의 언어를 Javascript로 통일하여 작업하는 것을 보고 저도 빠른 작업을 위해 React와 Express로 개발했습니다.</p>
              <p>그 결과, 기능이나 화면 개발은 속도를 낼 수 있었고, 공공데이터의 데이터를 가공하는데 시간을 집중할 수 있었습니다.</p>
              <p>데이터베이스도 평소 사용하는 RDB가 아닌 NoSQL인 MongoDB와 Mongoose로 개발하며 새로운 기술로 작업하는 재미를 느낄 수 있었습니다.</p>
              <p>"안전한 생수 찾기"를 개발한 이유는 유튜브를 통해 우리가 구입하여 마시는 생수에 문제가 발견되고도 그 업체에 대한 페널티가 미약하다는 것을 알게되어서 입니다.</p>
              <p>유통된 제품 중 수질 검사에 떨어진 제품을 피하기 위해서 입니다.</p>
              <p>제가 사용할 앱을 개발하니 더 재미있고 뜻깊은 프로젝트였습니다.</p>

              <br />
            </Box>
          </Fade>
        </Modal>
      </>
    )
  }
}
