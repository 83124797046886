import { AppBar, Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography, Link as LinkClick } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';

interface Props {
    window?: () => Window;
}

const drawerWidth = 240;
const navItems = [{name: 'Home', to: '/'}, {name: "About", to: "/about"}, {name: "Project", to: "/project"}];

function Navigation(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <LinkClick href="http://maportfolio.kro.kr" style={{cursor: "pointer"}}>Ma's Portfolio</LinkClick>
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <Link key={item.name} to={item.to} style={{ color: '#000', textDecoration: 'none', paddingLeft: 80}} >
                <ListItemText primary={item.name} />
              </Link> 
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }}>
            <a target={'_blank'} href="http://mablog.kro.kr" style={{ color: '#000', textDecoration: 'none', paddingLeft: 80}} rel="noreferrer">
              <ListItemText primary={"Blog"} />
            </a>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <AppBar component="nav">
            <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
            >
                <MenuIcon />
            </IconButton>
            <Typography
                variant="h4"
                component="div"
                textAlign="start"
                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              <LinkClick href="http://maportfolio.kro.kr" style={{cursor: "pointer", color: 'white'}}>Ma's Portfolio</LinkClick>
            </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                {navItems.map((item) => (
                <Link key={item.name} to={item.to} style={{textDecoration: 'none'}}>
                  <Button key={item.name} sx={{ color: '#fff' }}>
                    {item.name}
                  </Button>
                </Link>
                ))}
                <a target={'_blank'} rel="noreferrer" href="http://mablog.kro.kr" style={{textDecoration: 'none'}}>
                  <Button sx={{color: '#fff'}}>
                    {"Blog"}
                  </Button>
                </a>
            </Box>
            </Toolbar>
        </AppBar>
        <Box component="nav">
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
            {drawer}
            </Drawer>
        </Box>
        <Box component="main" sx={{ p: 3 }}>
            <Toolbar />
        </Box>
      </Box>
    </>
  )
}

export default Navigation;