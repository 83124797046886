import React from 'react';
import { Route, Routes } from 'react-router-dom';

import './App.css';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import Home from './pages/Home';
import About from './pages/About';
import Project from './pages/Project';
import RouteChangeTracker from './RouteChangeTracker';



// const darkTheme = createTheme({
//   typography: {
//     fontFamily: "'Gowun Dodum', sans-serif"
//   },
//   palette: {
//     mode: 'dark',
//     primary: {
//       main: '#000',
//     },
//     secondary: {
//       main: 'rgba(0, 0, 0, 0.04)',
//     },
//   },
// });

function App() {
  RouteChangeTracker();

  return (
    <div className="App">
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/project" element={<Project />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
