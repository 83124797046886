import { List, Typography, Divider, Card, CardMedia, CardContent, CardActions, CardHeader, CardActionArea, Alert, Stack, Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
// import Ebricks from './Ebricks';
// import Hibricks from './Hibricks';
import '../assets/company/css/company.css';
import profile from '../assets/company/images/profile.jpg';
import webcrwaling from '../assets/company/images/webcrwaling.png';
import yongclass from '../assets/company/images/yongclass.png';
import hibricks from '../assets/company/images/hibricks.png';
import ebricks from '../assets/company/images/ebricks.png';
import cah from '../assets/company/images/cah.png';
import pi3 from '../assets/company/images/pi3.png';

const LEGACY = "레거시 프로젝트 개편";
const NEW = "신규 프로젝트 개발";
// const DATA = [
//   {
//     image: hibricks,
//     url: "https://www.hibricks.com",
    
//   }
// ];
export default function Company() {
  
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);
  const [pi3Alert, setPi3Alert] = useState<boolean>(false);
  const [cahAlert, setCahAlert] = useState<boolean>(false);
  const [crawlAlert, setCrawlAlert] = useState<boolean>(false);

  const handleResize = () => {
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    }
  }, []); 

  useEffect(() => {
    if (crawlAlert) {
      setTimeout(() => {setCrawlAlert(false)}, 3000);
    }
    if (pi3Alert) {
      setTimeout(() => {setPi3Alert(false)}, 3000);
    }
    if (cahAlert) {
      setTimeout(() => {setCahAlert(false)}, 3000);
    }
  }, [crawlAlert, pi3Alert, cahAlert]);

  if (windowSize >= 600) {
    return (
      <>
        <List sx={{ bgcolor: 'background.paper', mb: 15 }}>
          <Typography variant="h3" sx={{ m: 5, my: 1, pb: 5 }}>PROJECT</Typography>
          <Divider variant="fullWidth" />
          <div className="card">
            <Card sx={{ maxWidth: 330, margin: 3, borderRadius: '8px' }} >
              {crawlAlert ? 
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="error">이 프로젝트는 사이트가 없습니다!</Alert>
              </Stack> : <></> }
              <CardHeader
                  avatar={
                    <Link to={'/'} style={{ color: '#000', textDecoration: 'none',}} >
                      <Avatar aria-label="recipe" src={profile} />
                    </Link>
                  }
                  sx={{ textAlign: 'start', borderBottom: '1px', }}
                  title="Webcrawling"
                  subheader={NEW}
                />
              <CardActionArea onClick={() => {setCrawlAlert(true)}} >
                <CardMedia
                  component="img"
                  height="140"
                  image={webcrwaling}
                  alt="webcrwaling"
                />
                <CardContent sx={{height: "189.52px"}}>
                  <Typography gutterBottom variant="body2" component="div" sx={{textAlign: 'start'}}>
                    <b>간단한 소개</b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <ul>
                      <li className="li">Linux(AWS), MySQL, Python로 개발</li>
                      <li className="li">Python, Mybatis, Spring Boot, Javascript, JSP, HTML, MySQL, Linux 기술 사용</li>
                      <li className="li">Selenium으로 로그인, 페이지 이동, HTML Crwaling 기능 개발</li>
                    </ul>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions sx={{justifyContent: 'flex-end'}}>
                <Typography component="div" sx={{textAlign: 'end'}} variant="body2" color="text.secondary">at <b>사회평론</b></Typography>
              </CardActions>
            </Card>


            <Card sx={{ maxWidth: 330, margin: 3, borderRadius: '8px' }} >
              <CardHeader
                  avatar={
                    <Link to={'/'} style={{ color: '#000', textDecoration: 'none',}} >
                      <Avatar aria-label="recipe" src={profile} />
                    </Link>
                  }
                  sx={{ textAlign: 'start', borderBottom: '1px', }}
                  title="Yongclass"
                  subheader={NEW}
                />
              <CardActionArea onClick={() => {window.open("https://www.yongclass.com/", "_blank")}} >
                <CardMedia
                  component="img"
                  height="140"
                  image={yongclass}
                  alt="yongclass"
                />
                <CardContent sx={{height: "189.52px"}}>
                  <Typography gutterBottom variant="body2" component="div" sx={{textAlign: 'start'}}>
                    <b>간단한 소개</b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <ul>
                      <li className="li">Linux(AWS), PostgreSQL, Java로 개발, 데이터 모델링 작업</li>
                      <li className="li">Java, JPA, Spring Boot, Javascript, Thymeleaf, HTML, PostgreSQL, Linux 기술 사용</li>
                      <li className="li">회원 기능, 도서검색, CMS 기능 등 화면과 기능 개발</li>
                    </ul>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions sx={{justifyContent: 'flex-end'}}>
                <Typography component="div" sx={{textAlign: 'end'}} variant="body2" color="text.secondary">at <b>사회평론</b></Typography>
              </CardActions>
            </Card>
            
            <Card sx={{ maxWidth: 330, margin: 3, borderRadius: '8px' }} >
              <CardHeader
                  avatar={
                    <Link to={'/'} style={{ color: '#000', textDecoration: 'none',}} >
                      <Avatar aria-label="recipe" src={profile} />
                    </Link>
                  }
                  sx={{ textAlign: 'start', borderBottom: '1px', }}
                  title="Hibricks"
                  subheader={LEGACY}
                />
              <CardActionArea onClick={() => {window.open("https://www.hibricks.com/", "_blank")}} >
                <CardMedia
                  component="img"
                  height="140"
                  image={hibricks}
                  alt="hibricks"
                />
                <CardContent sx={{height: "189.52px"}}>
                  <Typography gutterBottom variant="body2" component="div" sx={{textAlign: 'start'}}>
                    <b>간단한 소개</b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <ul>
                      <li className="li">Window Server, SQL Sever, C#에서 Linux(AWS), MariaDB, Java로 전환, 데이터 정리 및 버그 수정, 보완 작업</li>
                      <li className="li">Java, JPA, Spring Boot, Javascript, Thymeleaf, HTML, jQuery, MariaDB, Linux 기술 사용</li>
                      <li className="li">C#에서 Java로 전환, 속도 개선 및 트러블 슈팅</li>
                    </ul>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions sx={{justifyContent: 'flex-end'}}>
                <Typography component="div" sx={{textAlign: 'end'}} variant="body2" color="text.secondary">at <b>사회평론</b></Typography>
                {/* <Button size="small" target={'_blank'} href="https://www.hibricks.com/" style={{cursor: "pointer"}}>LINK</Button> */}
                {/* <Button size="small">Learn More</Button> */}
              </CardActions>
            </Card>

            
          </div>

          <div className='card'>
            <Card sx={{ maxWidth: 330, margin: 3, borderRadius: '8px' }} >
              <CardHeader
                avatar={
                  <Link to={'/'} style={{ color: '#000', textDecoration: 'none',}} >
                    <Avatar aria-label="recipe" src={profile} />
                  </Link>
                }
                title="Ebricks"
                subheader={LEGACY}
                sx={{ textAlign: 'start' }}
              />
              <CardActionArea onClick={() => {window.open("https://www.ebricks.co.kr/", "_blank")}} >
                <CardMedia
                  component="img"
                  height="140"
                  image={ebricks}
                  alt="green iguana"
                />
                <CardContent sx={{height: "189.52px"}}>
                  <Typography gutterBottom variant="body2" component="div" sx={{textAlign: 'start'}}>
                    <b>간단한 소개</b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <ul>
                      <li className="li">Naver Cloud, PHP에서 Linux(AWS), MariaDB, Java로 전환, 데이터 정리 및 에러 수정, 보완 작업 등 새로운 Website 개발</li>
                      <li className="li">Java, JPA, Spring Boot, Javascript, Thymeleaf, HTML, jQuery, MariaDB, Linux 기술 사용</li>
                      <li className="li">도서 검색, 도서 구매, 관리자 페이지 CMS 등 화면과 기능 개발</li>
                    </ul>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions sx={{justifyContent: 'flex-end'}}>
                <Typography component="div" sx={{textAlign: 'end'}} variant="body2" color="text.secondary">at <b>사회평론</b></Typography>
                {/* <Button size="small" target={'_blank'} href="https://www.ebricks.co.kr/" style={{cursor: "pointer"}}>Link</Button> */}
              </CardActions>
            </Card>


            <Card sx={{ maxWidth: 330, margin: 3, borderRadius: '8px' }} >
              {pi3Alert ? 
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="error">이 프로젝트는 사이트가 없습니다!</Alert>
              </Stack> : <></> }
              <CardHeader
                avatar={
                  <Link to={'/'} style={{ color: '#000', textDecoration: 'none',}} >
                    <Avatar aria-label="recipe" src={profile} />
                  </Link>
                }
                title="Prism i3"
                subheader={LEGACY}
                sx={{ textAlign: 'start' }}
              />
              <CardActionArea onClick={() => {setPi3Alert(true)}} >
                <CardMedia
                  component="img"
                  height="140"
                  image={pi3}
                  alt="pi3"
                />
                <CardContent sx={{height: "189.52px"}}>
                  <Typography gutterBottom variant="body2" component="div" sx={{textAlign: 'start'}}>
                    <b>간단한 소개</b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <ul>
                      <li className="li">Visual Basic, Oracle에서 Go, Fiber, PostgreSQL로 전환, 응용 프로그램에서 Website로 개발</li>
                      <li className="li">Go, Fiber, Javascript, HTML, jQuery, PostgreSQL 기술 사용</li>
                      <li className="li">회원 기능, Chart 기능 개발</li>
                    </ul>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions sx={{justifyContent: 'flex-end'}}>
                <Typography component="div" sx={{textAlign: 'end'}} variant="body2" color="text.secondary">at <b>갈렙ABC</b></Typography>
                {/* <Button size="small">Share</Button> */}
                {/* <Button size="small">Learn More</Button> */}
              </CardActions>
            </Card>

            <Card sx={{ maxWidth: 330, margin: 3, borderRadius: '8px' }} >
              {cahAlert ? 
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="error">이 프로젝트는 사이트가 없습니다!</Alert>
              </Stack> : <></> }
              <CardHeader
                avatar={
                  <Link to={'/'} style={{ color: '#000', textDecoration: 'none',}} >
                    <Avatar aria-label="recipe" src={profile} />
                  </Link>
                }
                title="CalebABC Hospital"
                subheader={LEGACY}
                sx={{ textAlign: 'start' }}
              />
              <CardActionArea onClick={() => {setCahAlert(true)}} >
                <CardMedia
                  component="img"
                  height="140"
                  image={cah}
                  alt="cag"
                />
                <CardContent sx={{height: "189.52px"}}>
                  <Typography gutterBottom variant="body2" component="div" sx={{textAlign: 'start'}}>
                    <b>간단한 소개</b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <ul>
                      <li className="li">Visual Basic, Oracle에서 Java, Spring Boot, PostgreSQL로 전환, 응용 프로그램에서 Website로 개발</li>
                      <li className="li">Java, MyBatis, Spring Boot, Javascript, HTML, PostgreSQL, Window Server 기술 사용</li>
                      <li className="li">회원 기능, Grid 기능 개발</li>
                    </ul>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions sx={{justifyContent: 'flex-end'}}>
                <Typography component="div" sx={{textAlign: 'end'}} variant="body2" color="text.secondary">at <b>갈렙ABC</b></Typography>
                {/* <Button size="small">Share</Button> */}
                {/* <Button size="small">Learn More</Button> */}
              </CardActions>
            </Card>
          </div>
        </List>
      </>
    )
  } else {
    return (
      <>
        <List sx={{ bgcolor: 'background.paper', mb: 15 }}>
        
          <Typography variant="h4" sx={{ m: 5, my: 1, pb: 5 }}>PROJECT</Typography>
        
          <Divider variant="fullWidth" />

          <div className='card'>
            <Card sx={{ maxWidth: 345, margin: 3, borderRadius: '8px' }} >
              {crawlAlert ? 
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="error">이 프로젝트는 사이트가 없습니다!</Alert>
              </Stack> : <></> }
              <CardHeader
                avatar={
                  <Link to={'/'} style={{ color: '#000', textDecoration: 'none',}} >
                    <Avatar aria-label="recipe" src={profile} />
                  </Link>
                }
                title="CalebABC Hospital"
                subheader={LEGACY}
                sx={{ textAlign: 'start' }}
              />
              <CardActionArea onClick={() => {setCrawlAlert(true)}} >
                <CardMedia
                  component="img"
                  height="140"
                  image={webcrwaling}
                  alt="cah"
                />
                <CardContent sx={{height: "189.52px"}}>
                  <Typography gutterBottom variant="body2" component="div" sx={{textAlign: 'start'}}>
                    <b>간단한 소개</b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <ul>
                      <li className="li">Linux(AWS), MySQL, Python로 개발</li>
                      <li className="li">Python, Mybatis, Spring Boot, Javascript, JSP, HTML, MySQL, Linux 기술 사용</li>
                      <li className="li">Selenium으로 로그인, 페이지 이동, HTML Crwaling 기능 개발</li>
                    </ul>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions sx={{justifyContent: 'flex-end'}}>
                <Typography component="div" sx={{textAlign: 'end'}} variant="body2" color="text.secondary">at <b>갈렙ABC</b></Typography>
                {/* <Button size="small">Share</Button> */}
                {/* <Button size="small">Learn More</Button> */}
              </CardActions>
            </Card>
          </div>



          <div className="card">
            <Card sx={{ maxWidth: 345, margin: 3, borderRadius: '8px' }} >
              <CardHeader
                avatar={
                  <Link to={'/'} style={{ color: '#000', textDecoration: 'none',}} >
                    <Avatar aria-label="recipe" src={profile} />
                  </Link>
                }
                sx={{ textAlign: 'start' }}
                title="Yongclass"
                subheader={NEW}
              />
              <CardActionArea onClick={() => {window.open("https://www.yongclass.com/", "_blank")}} >
                <CardMedia
                  component="img"
                  height="140"
                  image={yongclass}
                  alt="yongclass"
                />
                <CardContent>
                  <Typography gutterBottom variant="body2" component="div" sx={{textAlign: 'start'}}>
                    <b>간단한 소개</b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <ul>
                      <li className="li">Linux(AWS), PostgreSQL, Java로 개발, 데이터 모델링 작업</li>
                      <li className="li">Java, JPA, Spring Boot, Javascript, Thymeleaf, HTML, PostgreSQL, Linux 기술 사용</li>
                      <li className="li">회원 기능, 도서검색, 관리자 페이지 CMS 등 화면과 기능 개발</li>
                    </ul>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions sx={{justifyContent: 'flex-end'}}>
                <Typography component="div" sx={{textAlign: 'end'}} variant="body2" color="text.secondary">at <b>사회평론</b></Typography>
                {/* <Button size="small" target={'_blank'} href="https://www.hibricks.com/" style={{cursor: "pointer"}}>LINK</Button> */}
                {/* <Button size="small">Learn More</Button> */}
              </CardActions>
            </Card>
          </div>
        
          <div className="card">
            <Card sx={{ maxWidth: 345, margin: 3, borderRadius: '8px' }} >
              <CardHeader
                avatar={
                  <Link to={'/'} style={{ color: '#000', textDecoration: 'none',}} >
                    <Avatar aria-label="recipe" src={profile} />
                  </Link>
                }
                sx={{ textAlign: 'start' }}
                title="Hibricks"
                subheader={LEGACY}
              />
              <CardActionArea onClick={() => {window.open("https://www.hibricks.com/", "_blank")}} >
                <CardMedia
                  component="img"
                  height="140"
                  image={hibricks}
                  alt="hibricks"
                />
                <CardContent>
                  <Typography gutterBottom variant="body2" component="div" sx={{textAlign: 'start'}}>
                    <b>간단한 소개</b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <ul>
                      <li className="li">Window Server, SQL Sever, C#에서 Linux(AWS), MariaDB, Java로 전환, 데이터 정리 및 버그 수정, 보완 작업</li>
                      <li className="li">Java, JPA, Spring Boot, Javascript, Thymeleaf, HTML, jQuery, MariaDB, Linux 기술 사용</li>
                      <li className="li">C#에서 Java로 전환, 속도 개선 및 트러블 슈팅</li>
                    </ul>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions sx={{justifyContent: 'flex-end'}}>
                <Typography component="div" sx={{textAlign: 'end'}} variant="body2" color="text.secondary">at <b>사회평론</b></Typography>
                {/* <Button size="small" target={'_blank'} href="https://www.hibricks.com/" style={{cursor: "pointer"}}>LINK</Button> */}
                {/* <Button size="small">Learn More</Button> */}
              </CardActions>
            </Card>
          </div>

          <div className="card">
            <Card sx={{ maxWidth: 345, margin: 3, borderRadius: '8px' }} >
              <CardHeader
                avatar={
                  <Link to={'/'} style={{ color: '#000', textDecoration: 'none',}} >
                    <Avatar aria-label="recipe" src={profile} />
                  </Link>
                }
                title="Ebricks"
                subheader={LEGACY}
                sx={{ textAlign: 'start' }}
              />
              <CardActionArea onClick={() => {window.open("https://www.ebricks.co.kr/", "_blank")}} >
                <CardMedia
                  component="img"
                  height="140"
                  image={ebricks}
                  alt="ebricks"
                />
                <CardContent>
                  <Typography gutterBottom variant="body2" component="div" sx={{textAlign: 'start'}}>
                    <b>간단한 소개</b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <ul>
                      <li className="li">Naver Cloud, PHP에서 Linux(AWS), MariaDB, Java로 전환, 데이터 정리 및 에러 수정, 보완 작업 등 새로운 Website 개발</li>
                      <li className="li">Java, JPA, Spring Boot, Javascript, Thymeleaf, HTML, jQuery, MariaDB, Linux 기술 사용</li>
                      <li className="li">도서 검색, 도서 구매, 관리자 페이지 CMS 등 화면과 기능 개발</li>
                    </ul>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions sx={{justifyContent: 'flex-end'}}>
                <Typography component="div" sx={{textAlign: 'end'}} variant="body2" color="text.secondary">at <b>사회평론</b></Typography>
                {/* <Button size="small" target={'_blank'} href="https://www.ebricks.co.kr/" style={{cursor: "pointer"}}>Link</Button> */}
              </CardActions>
            </Card>

          </div>
          
          <div className="card">
            <Card sx={{ maxWidth: 345, margin: 3, borderRadius: '8px' }} >
              {pi3Alert ? 
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="error">이 프로젝트는 사이트가 없습니다!</Alert>
              </Stack> : <></> }
              <CardHeader
                avatar={
                  <Link to={'/'} style={{ color: '#000', textDecoration: 'none',}} >
                    <Avatar aria-label="recipe" src={profile} />
                  </Link>
                }
                title="Prism i3"
                subheader={NEW}
                sx={{ textAlign: 'start' }}
              />
              <CardActionArea onClick={() => {setPi3Alert(true)}} >
                <CardMedia
                  component="img"
                  height="140"
                  image={pi3}
                  alt="pi3"
                />
                <CardContent sx={{height: "189.52px"}}>
                  <Typography gutterBottom variant="body2" component="div" sx={{textAlign: 'start'}}>
                    <b>간단한 소개</b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <ul>
                      <li className="li">Visual Basic, Oracle에서 Go, Fiber, PostgreSQL로 전환, 응용 프로그램에서 Website로 개발</li>
                      <li className="li">Go, Fiber, Javascript, HTML, jQuery, PostgreSQL 기술 사용</li>
                      <li className="li">회원 기능, Chart 기능 개발</li>
                    </ul>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions sx={{justifyContent: 'flex-end'}}>
                <Typography component="div" sx={{textAlign: 'end'}} variant="body2" color="text.secondary">at <b>갈렙ABC</b></Typography>
                {/* <Button size="small">Share</Button> */}
                {/* <Button size="small">Learn More</Button> */}
              </CardActions>
            </Card>
          </div>
            
          <div className='card'>
            <Card sx={{ maxWidth: 345, margin: 3, borderRadius: '8px' }} >
              {cahAlert ? 
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="error">이 프로젝트는 사이트가 없습니다!</Alert>
              </Stack> : <></> }
              <CardHeader
                avatar={
                  <Link to={'/'} style={{ color: '#000', textDecoration: 'none',}} >
                    <Avatar aria-label="recipe" src={profile} />
                  </Link>
                }
                title="CalebABC Hospital"
                subheader={LEGACY}
                sx={{ textAlign: 'start' }}
              />
              <CardActionArea onClick={() => {setCahAlert(true)}} >
                <CardMedia
                  component="img"
                  height="140"
                  image={cah}
                  alt="cah"
                />
                <CardContent sx={{height: "189.52px"}}>
                  <Typography gutterBottom variant="body2" component="div" sx={{textAlign: 'start'}}>
                    <b>간단한 소개</b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <ul>
                      <li className="li">Visual Basic, Oracle에서 Java, Spring Boot, PostgreSQL로 전환, 응용 프로그램에서 Website로 개발</li>
                      <li className="li">Java, MyBatis, Spring Boot, Javascript, HTML, jQuery, PostgreSQL, Window Server 기술 사용</li>
                      <li className="li">회원 기능, Grid 기능 개발</li>
                    </ul>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions sx={{justifyContent: 'flex-end'}}>
                <Typography component="div" sx={{textAlign: 'end'}} variant="body2" color="text.secondary">at <b>갈렙ABC</b></Typography>
                {/* <Button size="small">Share</Button> */}
                {/* <Button size="small">Learn More</Button> */}
              </CardActions>
            </Card>
          </div>
       </List>
      </>
    )
  }
}
